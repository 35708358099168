import React, { useMemo } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { BoxProps } from "@mui/system";
import { TTNDeliveryTypes } from "./types";
import { BoxIcon, ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import TTNHeader from "./components/ttn-header";
import { TTNDeliveryTypeButton, TTNSectionTitle } from "./components/styled";
import { useFormContext } from "react-hook-form";
import ArrowRightIcon from "./components/icons";
import { COLORS } from "../../configs/colors.style";
import useGetWaybills, {
  WaybillType,
} from "../../services/ttn/use-get-waybills";
import useToggle from "../../hooks/use-toggle";
import styled from "styled-components";
import { WaybillSaveType } from "../../services/ttn/use-create-waybill";
import useGetTTNCompanyInfo from "../../services/ttn/use-get-ttn-company-info";

const DeliveryTypes = [
  TTNDeliveryTypes.WarehouseToWarehouse,
  TTNDeliveryTypes.SellerToClient,
  TTNDeliveryTypes.Recycle,
];

const HistoryButton = styled(ButtonBase)`
  padding: ${({ theme }) => theme.spacing(1.25)};
  background-color: ${COLORS?.lightBg};
  border-radius: 10px;
  font-size: 15px;
  width: max-content;
  max-width: 100%;
  justify-content: start;

  p {
    font-size: 15px;
    font-weight: 500;
    margin-right: ${({ theme }) => theme.spacing(1.25)};)};
  }

  svg {
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    margin-right: ${({ theme }) => theme.spacing(0.75)};
  }
`;

const SelectDeliveryType: React.FC<BoxProps> = ({ sx, ...props }) => {
  const { t } = useTranslation();
  const form = useFormContext<WaybillSaveType>();

  const TTNCompanyInfo = useGetTTNCompanyInfo();

  const historyToggle = useToggle();

  const waybills = useGetWaybills();

  const DeliveryType = form.watch("delivery_type");

  const historyItems = useMemo(() => {
    if (historyToggle?.isOpen) return waybills?.data?.slice(0, 5);

    return waybills?.data?.slice(0, 2);
  }, [waybills?.data, historyToggle?.isOpen]);

  const onSetHIstoryItem = (waybill: WaybillType) => {
    form.reset({
      sender: waybill?.sender,
      receiver: waybill?.receiver,
      products: waybill?.products,
      delivery_type: waybill?.delivery_type,
      created_at: new Date().toISOString(),
    });
  };

  return (
    <>
      <TTNHeader title="TTN" />
      {waybills?.data && waybills?.data?.length > 0 && (
        <Box sx={{ p: 2, pb: 0 }}>
          <TTNSectionTitle>{t("History")}</TTNSectionTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.25,
              mt: 1,
              justifyContent: "start",
              ...sx,
            }}
            {...props}
          >
            {historyItems?.map((item, index) => {
              const hasShowMore =
                waybills?.data &&
                waybills?.data?.length > 2 &&
                !historyToggle?.isOpen &&
                index === 1;

              return (
                <Box
                  sx={{
                    display: "flex",
                    gap: 1.25,
                    width: hasShowMore ? "calc(100vw - 82px)" : "100%",
                  }}
                >
                  <HistoryButton onClick={() => onSetHIstoryItem(item)}>
                    <ArrowRightIcon />
                    <Typography noWrap sx={{ flex: 1 }}>
                      {item?.receiver?.name}
                    </Typography>

                    <BoxIcon color={COLORS?.textGray} />
                    <Typography noWrap sx={{ flex: 1, textAlign: "start" }}>
                      {item?.products
                        ?.map((product) => product?.product_name)
                        .join(", ")}
                    </Typography>
                  </HistoryButton>
                  {hasShowMore && (
                    <HistoryButton
                      onClick={historyToggle?.open}
                      sx={{
                        minWidth: "min-content",
                        color: "#007AFF",
                      }}
                    >
                      +{Math?.min(waybills?.data?.length || 0, 5) - 2}
                    </HistoryButton>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box sx={{ p: 2 }}>
        <TTNSectionTitle>{t("Services")}</TTNSectionTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.25,
            mt: 1,
            ...sx,
          }}
          {...props}
        >
          {DeliveryTypes.map((item) => (
            <TTNDeliveryTypeButton
              onClick={() => {
                form.setValue("delivery_type", item);

                if (TTNCompanyInfo?.data?.name && TTNCompanyInfo?.data?.inn)
                  form.setValue("sender", {
                    name: TTNCompanyInfo?.data?.name,
                    tin_or_pinfl: TTNCompanyInfo?.data?.inn,
                  });
              }}
            >
              {t(`ttn.delivery-type.${item}`)} <ChevronRight />
            </TTNDeliveryTypeButton>
          ))}
        </Box>
      </Box>
      {DeliveryType && <p>{DeliveryType}</p>}
    </>
  );
};

export default SelectDeliveryType;
