import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../redux/hook";
import { setAuth } from "../redux/slices/auth-slice";

const useGetTokenFromSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = searchParams.get("token");

    if (!!token) {
      dispatch(
        setAuth({
          isAuthenticated: true,
          refresh_token: null,
          token: token,
        })
      );

      setSearchParams({});
    }
  }, [searchParams]);
};

export default useGetTokenFromSearchParams;
