import styled from "styled-components";
import { COLORS } from "../../../../../configs/colors.style";

export const SendCodeModalWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const PhoneNumberWrapper = styled.span`
  background: ${COLORS.inputFill};
  padding: 8px 4px 8px 8px;
  margin: 12px 0 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

export const PhoneNumber = styled.span`
  color: ${COLORS.textMain};
  font-weight: 500;
  font-size: 14px;
`;

export const IconButton = styled.button`
  background: #efefef;
  border-radius: 4.72727px;
  margin-left: 4px;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
`;
