import { useInfiniteQuery } from "react-query";
import request from "../request";
import { useTranslation } from "react-i18next";

interface ProductType {
  pkey: string;
  tin?: string;
  pinfl?: string | null;
  mxikCode: string;
  mxikFullName: string;
  groupName: string;
  className: string;
  positionName: string;
  subPositionName: string;
  brandName: string;
  attributeName: string;
  internationalCode: string;
  unitCode: null;
  unitName: null;
  unitValue: null;
  commonUnitCode: null;
  commonUnitName: null;
  units: null;
  packageNames: {
    code: number;
    mxikCode: string;
    nameUz?: string;
    packageType: string;
    nameRu?: string;
    nameLat?: string;
  }[];
  label: number;
  usePackage: string;
  usePackage2: string;
  isActive: number;
  createdAt: string;
}

const fetchCategories = ({
  pageParam = 0,
  queryKey,
}: {
  pageParam: number;
  queryKey: any;
}) => {
  const [, customParams] = queryKey;
  const { tin, search, pageSize, lang } = customParams;

  return request.private.get<{ count: number; result: Array<ProductType> }>(
    `v1/waybill/catalog?tin=${tin}&page=${pageParam}&page_size=${pageSize}&lang=${lang}&search=${search}`
  );
};

const useGetTTNProductCategories = ({
  tin = "",
  search = "",
  pageSize = 2,
}: {
  tin?: string;
  search?: string;
  pageSize: number;
}) => {
  const { i18n } = useTranslation();

  const params = {
    tin,
    search,
    pageSize,
    lang: i18n?.language,
  };

  // @ts-expect-error
  return useInfiniteQuery(["v1/waybill/catalog", params], fetchCategories, {
    enabled: !!tin,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.data?.count > pageSize * pages.length
        ? pages.length
        : undefined;
    },
  });
};

export default useGetTTNProductCategories;
