import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { TTNIconButton, TTNSectionTitle, TTNTextField } from "./styled";
import { WaybillSaveType } from "../../../services/ttn/use-create-waybill";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { COLORS } from "../../../configs/colors.style";
import useGetTTNProductCategories from "../../../services/ttn/use-get-tasnif-products";
import OutlinedDeleteIcon from "../../../components/icons/outlined-delete";
import { useTranslation } from "react-i18next";
import useGetTTNCompanyInfo from "../../../services/ttn/use-get-ttn-company-info";
import InfiniteLoader from "../../../components/infinite-loader/infinite-loader";
import useDebounce from "../../../hooks/use-debounce";

interface TTNProductItemProps {
  index: number;
  product: FieldArrayWithId<WaybillSaveType, "products", "id">;
}

const TTNProductItem: React.FC<TTNProductItemProps> = ({ index }) => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const dSearch = useDebounce(search, 300);

  const form = useFormContext<WaybillSaveType>();
  const { remove } = useFieldArray({
    control: form.control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "products", // unique name for your Field Array
  });

  const TTNCompanyInfo = useGetTTNCompanyInfo();
  const {
    data: categoriesData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useGetTTNProductCategories({
    tin: TTNCompanyInfo?.data?.inn,
    search: dSearch,
    pageSize: 20,
  });

  const categories = categoriesData?.pages
    ?.map((page) => page.data?.result)
    .flat();

  const product = form.watch(`products.${index}`);
  const productCatalogCode = form.watch(`products.${index}.catalog_code`);

  const category = useMemo(
    () => categories?.find((p) => p?.mxikCode === productCatalogCode),
    [categories, productCatalogCode]
  );

  useEffect(() => {
    if (product?.catalog_code && category?.packageNames?.length) {
      form.setValue(
        `products.${index}.package_code`,
        category?.packageNames[0].code.toString()
      );
    }
  }, [product?.catalog_code, category]);

  if (!product) return null;

  return null;
  // return (
  //   <>
  //     <TTNSectionTitle ml={0.5}>
  //       {t("Product")} {index === 0 ? "" : index + 1}
  //     </TTNSectionTitle>
  //     <Box sx={{ display: "flex", gap: 1 }}>
  //       <TTNTextField
  //         label={t("Title")}
  //         variant="filled"
  //         sx={{ flex: 1 }}
  //         {...form.register(`products.${index}.product_name`)}
  //         error={
  //           !!form?.formState?.errors?.products?.[index]?.product_name?.message
  //         }
  //         helperText={
  //           form?.formState?.errors?.products?.[index]?.product_name?.message
  //             ? t(
  //                 form?.formState?.errors?.products?.[index]?.product_name
  //                   ?.message || ""
  //               )
  //             : ""
  //         }
  //       />
  //       <TTNIconButton
  //         color="secondary"
  //         onClick={() => {
  //           console.log("remove", index);
  //           remove(index);
  //         }}
  //       >
  //         <OutlinedDeleteIcon
  //           style={{ minWidth: "24px", height: "24px", margin: 0 }}
  //         />
  //       </TTNIconButton>
  //     </Box>

  //     <Controller
  //       name={`products.${index}.catalog_code`}
  //       control={form.control}
  //       render={({ field }) => (
  //         <FormControl
  //           fullWidth
  //           sx={{
  //             "& label.MuiInputLabel-shrink": {
  //               color: COLORS.textGray,
  //               fontSize: "13px",
  //               fontWeight: 500,
  //               top: "16px",
  //             },
  //           }}
  //         >
  //           <Autocomplete
  //             disablePortal
  //             options={categories || []}
  //             getOptionLabel={(option) =>
  //               `${option.mxikCode} ${option.mxikFullName}`
  //             }
  //             renderInput={(params) => (
  //               <TTNTextField
  //                 {...params}
  //                 value={search}
  //                 onChange={(e) => setSearch(e.target.value)}
  //                 label={t("Catalog")}
  //                 InputProps={{
  //                   ...params.InputProps,
  //                   endAdornment: (
  //                     <>
  //                       {isLoading ? <CircularProgress size={20} /> : null}
  //                       {params.InputProps.endAdornment}
  //                     </>
  //                   ),
  //                 }}
  //                 sx={{
  //                   "& .MuiOutlinedInput-notchedOutline": {
  //                     border: "none",
  //                   },
  //                 }}
  //               />
  //             )}
  //             ListboxComponent={(listboxProps) => (
  //               <div {...listboxProps}>
  //                 <InfiniteLoader
  //                   hasMore={hasNextPage}
  //                   loadMore={fetchNextPage}
  //                 >
  //                   {listboxProps.children}
  //                 </InfiniteLoader>
  //               </div>
  //             )}
  //             onChange={(event, newValue) => {
  //               field.onChange(newValue?.mxikCode || "");

  //               const categoryName = newValue?.mxikFullName || "";
  //               form.setValue(`products.${index}.catalog_name`, categoryName);
  //             }}
  //           />
  //           {form?.formState?.errors?.products?.[index]?.catalog_code
  //             ?.message && (
  //             <FormHelperText error>
  //               {t(
  //                 form.formState?.errors?.products?.[index]?.catalog_code
  //                   ?.message || ""
  //               )}
  //             </FormHelperText>
  //           )}
  //         </FormControl>
  //       )}
  //     />

  //     <Controller
  //       name={`products.${index}.price`}
  //       control={form.control}
  //       render={({ field }) => (
  //         <TTNTextField
  //           label={t("Price")}
  //           variant="filled"
  //           type="number"
  //           error={!!form?.formState?.errors?.products?.[index]?.price?.message}
  //           helperText={
  //             form?.formState?.errors?.products?.[index]?.price?.message
  //               ? t(
  //                   form?.formState?.errors?.products?.[index]?.price
  //                     ?.message || ""
  //                 )
  //               : ""
  //           }
  //           {...field}
  //         />
  //       )}
  //     />
  //     <Box
  //       sx={{
  //         display: "flex",
  //         items: "center",
  //         gap: 1,
  //       }}
  //     >
  //       <Controller
  //         name={`products.${index}.package_code`}
  //         control={form.control}
  //         render={({ field }) => {
  //           return (
  //             <FormControl
  //               fullWidth
  //               sx={{
  //                 "& label.MuiInputLabel-shrink": {
  //                   color: COLORS.textGray,
  //                   fontSize: "13px",
  //                   fontWeight: 500,
  //                   top: "16px",
  //                 },
  //                 flex: 1,
  //               }}
  //             >
  //               <InputLabel id="demo-simple-select-label">
  //                 {t("Type of packaging")}
  //               </InputLabel>
  //               <Select
  //                 labelId="demo-simple-select-label"
  //                 id="demo-simple-select"
  //                 label={t("Catalog")}
  //                 variant="filled"
  //                 disabled={!category}
  //                 {...field}
  //                 onChange={(e) => {
  //                   field.onChange(e);

  //                   const packageItem = category?.packageNames?.find(
  //                     (p) => p.code.toString() == e.target.value
  //                   );

  //                   form.setValue(
  //                     `products.${index}.package_name`,
  //                     (i18n.language == "ru"
  //                       ? packageItem?.nameRu
  //                       : packageItem?.nameLat) as string
  //                   );
  //                 }}
  //               >
  //                 {category?.packageNames?.map((packageItem) => (
  //                   <MenuItem
  //                     key={packageItem.code}
  //                     value={packageItem.code.toString()}
  //                   >
  //                     {i18n.language == "ru"
  //                       ? packageItem.nameRu
  //                       : packageItem.nameLat}
  //                   </MenuItem>
  //                 ))}
  //               </Select>
  //               {form?.formState?.errors?.products?.[index]?.package_code
  //                 ?.message && (
  //                 <FormHelperText error>
  //                   {t(
  //                     form.formState.errors?.products?.[index]?.package_code
  //                       ?.message || ""
  //                   )}
  //                 </FormHelperText>
  //               )}
  //             </FormControl>
  //           );
  //         }}
  //       />
  //       <Controller
  //         name={`products.${index}.amount`}
  //         control={form.control}
  //         render={({ field }) => (
  //           <TTNTextField
  //             label={t("Quantity")}
  //             variant="filled"
  //             type="number"
  //             sx={{ flex: 1 }}
  //             error={
  //               !!form?.formState?.errors?.products?.[index]?.amount?.message
  //             }
  //             helperText={
  //               form?.formState?.errors?.products?.[index]?.amount?.message
  //                 ? t(
  //                     form.formState.errors.products[index].amount.message || ""
  //                   )
  //                 : ""
  //             }
  //             {...field}
  //           />
  //         )}
  //       />
  //     </Box>
  //     <Box
  //       sx={{
  //         display: "flex",
  //         items: "center",
  //         gap: 1,
  //       }}
  //     >
  //       <Controller
  //         name={`products.${index}.weight_netto`}
  //         control={form.control}
  //         render={({ field }) => (
  //           <TTNTextField
  //             label={t("Net weight")}
  //             variant="filled"
  //             type="number"
  //             sx={{ flex: 1 }}
  //             error={
  //               !!form?.formState?.errors?.products?.[index]?.weight_netto
  //                 ?.message
  //             }
  //             helperText={
  //               form?.formState?.errors?.products?.[index]?.weight_netto
  //                 ?.message
  //                 ? t(
  //                     form.formState.errors.products[index].weight_netto.message
  //                   )
  //                 : ""
  //             }
  //             {...field}
  //           />
  //         )}
  //       />
  //       <Controller
  //         name={`products.${index}.weight_brutto`}
  //         control={form.control}
  //         render={({ field }) => (
  //           <TTNTextField
  //             label={t("Gross weight")}
  //             variant="filled"
  //             type="number"
  //             sx={{ flex: 1 }}
  //             error={
  //               !!form?.formState?.errors?.products?.[index]?.weight_brutto
  //                 ?.message
  //             }
  //             helperText={
  //               form?.formState?.errors?.products?.[index]?.weight_brutto
  //                 ?.message
  //                 ? t(
  //                     form?.formState?.errors?.products?.[index]?.weight_brutto
  //                       ?.message
  //                   )
  //                 : ""
  //             }
  //             {...field}
  //           />
  //         )}
  //       />
  //     </Box>
  //   </>
  // );
};

export default TTNProductItem;
