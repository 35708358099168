import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { useEffect, useMemo } from "react";
import { TTNIconButton, TTNSectionTitle, TTNTextField } from "./styled";
import { WaybillSaveType } from "../../../services/ttn/use-save-waybill";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { COLORS } from "../../../configs/colors.style";
import useGetTTNProductCategories from "../../../services/ttn/use-get-tasnif-products";
import OutlinedDeleteIcon from "../../../components/icons/outlined-delete";

interface TTNProductItemProps {
  index: number;
  product: FieldArrayWithId<WaybillSaveType, "products", "id">;
}

const TTNProductItem: React.FC<TTNProductItemProps> = ({ index }) => {
  const form = useFormContext<WaybillSaveType>();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: form.control, // control props comes from useForm (optional: if you are using FormProvider)
      name: "products", // unique name for your Field Array
    }
  );

  const categories = useGetTTNProductCategories({});

  const product = form.watch(`products.${index}`);

  const category = useMemo(
    () => categories?.data?.find((p) => p?.mxikCode === product?.catalog_code),
    [categories, product?.catalog_code]
  );

  useEffect(() => {
    if (product?.catalog_code && category?.packageNames?.length === 1) {
      form.setValue(
        `products.${index}.package_code`,
        category?.packageNames[0].code.toString()
      );
    }
  }, [product?.catalog_code, category]);

  if (!product) return null;

  return (
    <>
      <TTNSectionTitle ml={0.5}>
        Продукт {index === 0 ? "" : index + 1}
      </TTNSectionTitle>
      <Box sx={{ display: "flex", gap: 1 }}>
        <TTNTextField
          label="Названия"
          variant="filled"
          sx={{ flex: 1 }}
          {...form.register(`products.${index}.product_name`)}
        />
        <TTNIconButton
          color="secondary"
          onClick={() => {
            console.log("remove", index);
            remove(index);
          }}
        >
          <OutlinedDeleteIcon
            style={{ minWidth: "24px", height: "24px", margin: 0 }}
          />
        </TTNIconButton>
      </Box>

      <Controller
        name={`products.${index}.catalog_code`}
        control={form.control}
        render={({ field }) => (
          <FormControl
            fullWidth
            sx={{
              "& label.MuiInputLabel-shrink": {
                color: COLORS.textGray,
                fontSize: "13px",
                fontWeight: 500,
                top: "16px",
              },
            }}
          >
            <InputLabel id="demo-simple-select-label">Каталог</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Каталог"
              variant="filled"
              sx={{ ".MuiPaper-root": { borderRadius: "10px !important" } }}
              {...field}
            >
              {categories?.data?.map((category) => (
                <MenuItem
                  value={category?.mxikCode}
                  sx={{
                    whiteSpace: "normal",
                    p: 1.5,
                    borderBottom: "1px solid #F9F9F9",
                  }}
                >
                  {category?.mxikFullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <TTNTextField
        label="Цена"
        variant="filled"
        type="number"
        {...form.register(`products.${index}.price`)}
      />
      <Box
        sx={{
          display: "flex",
          items: "center",
          gap: 1,
        }}
      >
        <Controller
          name={`products.${index}.package_code`}
          control={form.control}
          render={({ field }) => {
            return (
              <FormControl
                fullWidth
                sx={{
                  "& label.MuiInputLabel-shrink": {
                    color: COLORS.textGray,
                    fontSize: "13px",
                    fontWeight: 500,
                    top: "16px",
                  },
                  flex: 1,
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Тип упаковки
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Каталог"
                  variant="filled"
                  disabled={!category}
                  {...field}
                >
                  {category?.packageNames?.map((packageItem) => (
                    <MenuItem value={packageItem.code.toString()}>
                      {packageItem.nameRu}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />
        <Controller
          name={`products.${index}.amount`}
          control={form.control}
          render={({ field }) => (
            <TTNTextField
              label="Количество"
              variant="filled"
              type="number"
              sx={{ flex: 1 }}
              {...field}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          items: "center",
          gap: 1,
        }}
      >
        <Controller
          name={`products.${index}.weight_netto`}
          control={form.control}
          render={({ field }) => (
            <TTNTextField
              label="Вес нетто"
              variant="filled"
              type="number"
              sx={{ flex: 1 }}
              {...field}
            />
          )}
        />
        <Controller
          name={`products.${index}.weight_brutto`}
          control={form.control}
          render={({ field }) => (
            <TTNTextField
              label="Вес брутто"
              variant="filled"
              type="number"
              sx={{ flex: 1 }}
              {...field}
            />
          )}
        />
      </Box>
    </>
  );
};

export default TTNProductItem;
