import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { TTNSectionTitle } from "./styled";

interface DataFieldProps extends BoxProps {
  label: string;
  value: string;
}

const DataField: React.FC<DataFieldProps> = ({
  label,
  value,
  sx,
  ...props
}) => {
  return (
    <Box sx={{ py: 0.375, px: 0.5, ...sx }} {...props}>
      <TTNSectionTitle>{label}</TTNSectionTitle>
      <Typography color="#454545" fontWeight={500} fontSize={15} mt={0.625}>
        {value}
      </Typography>
    </Box>
  );
};

export default DataField;
