import { useMutation } from "react-query";
import request from "../request";

interface Status {
  active: boolean;
  isSafe: boolean;
  suspended: boolean;
  vatPayer: boolean;
  vatRegCode: string;
}

interface Taxpayer {
  code: number;
  name: string;
  nameRu: string;
  nameUz: string;
}

interface TaxpayerData {
  account: string;
  accountant: string;
  address: string;
  director: string;
  directorPinfl: number;
  directorTin: string;
  facturaId: string;
  facturaProductId: string;
  has_branch: number;
  isBudget: number;
  isItd: boolean;
  mfo: string;
  na1Code: number;
  na1Name: string;
  name: string;
  ns10Code: number;
  ns11Code: number;
  oked: string;
  peasantFarm: boolean;
  personalNum: string;
  privateNotary: boolean;
  product: null;
  selfEmployment: boolean;
  shortName: string;
  status: Status;
  statusCode: number;
  statusName: string;
  taxpayer: Taxpayer;
  tin: string;
}

const useGetUserInformation = () => {
  return useMutation({
    mutationFn: (tin_or_pinfl: string | number) =>
      request.private
        .get<TaxpayerData>(
          `v1/waybill/user-information?tin_or_pinfl=${tin_or_pinfl}`
        )
        .then((res) => res.data),
  });
};

export default useGetUserInformation;
