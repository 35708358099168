export const waybills = {
  waybills: [
    {
      id: 46,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0ebf641254fcd6a64e03",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: null,
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 118,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "46",
        },
        {
          id: 119,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "46",
        },
        {
          id: 120,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "46",
        },
      ],
    },
    {
      id: 45,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0ebc641254fcd6a64e02",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: null,
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 115,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "45",
        },
        {
          id: 116,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "45",
        },
        {
          id: 117,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "45",
        },
      ],
    },
    {
      id: 44,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0ebb641254fcd6a64e01",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: null,
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 112,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "44",
        },
        {
          id: 113,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "44",
        },
        {
          id: 114,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "44",
        },
      ],
    },
    {
      id: 43,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0eb9641254fcd6a64e00",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: null,
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 109,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "43",
        },
        {
          id: 110,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "43",
        },
        {
          id: 111,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "43",
        },
      ],
    },
    {
      id: 42,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0e6f641254fcd6a64dff",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: null,
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 106,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "42",
        },
        {
          id: 107,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "42",
        },
        {
          id: 108,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "42",
        },
      ],
    },
    {
      id: 41,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0e20641254fcd6a64dfe",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 103,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "41",
        },
        {
          id: 104,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "41",
        },
        {
          id: 105,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "41",
        },
      ],
    },
    {
      id: 40,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c06b6641254fcd6a64dfd",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 100,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "40",
        },
        {
          id: 101,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "40",
        },
        {
          id: 102,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "40",
        },
      ],
    },
    {
      id: 39,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c06ac641254fcd6a64dfc",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 97,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "39",
        },
        {
          id: 98,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "39",
        },
        {
          id: 99,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "39",
        },
      ],
    },
    {
      id: 38,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0681641254fcd6a64dfb",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 94,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "38",
        },
        {
          id: 95,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "38",
        },
        {
          id: 96,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "38",
        },
      ],
    },
    {
      id: 37,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0673641254fcd6a64dfa",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 91,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "37",
        },
        {
          id: 92,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "37",
        },
        {
          id: 93,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "37",
        },
      ],
    },
    {
      id: 36,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0653641254fcd6a64df9",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: null,
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 88,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "36",
        },
        {
          id: 89,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "36",
        },
        {
          id: 90,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "36",
        },
      ],
    },
    {
      id: 35,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c0607641254fcd6a64df7",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: null,
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 85,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "35",
        },
        {
          id: 86,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "35",
        },
        {
          id: 87,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "35",
        },
      ],
    },
    {
      id: 34,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "677c05b0641254fcd6a64df6",
      delivery_type: 1,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: null,
        contract_date: null,
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 82,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "34",
        },
        {
          id: 83,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "34",
        },
        {
          id: 84,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "34",
        },
      ],
    },
    {
      id: 33,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "676148b40d91f528e2fe4fba",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 79,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "33",
        },
        {
          id: 80,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "33",
        },
        {
          id: 81,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "33",
        },
      ],
    },
    {
      id: 32,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "675d7015f7c8630cb6380f22",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 76,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "32",
        },
        {
          id: 77,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "32",
        },
        {
          id: 78,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "32",
        },
      ],
    },
    {
      id: 31,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "673460736b6329071eab9c37",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 73,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "31",
        },
        {
          id: 74,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "31",
        },
        {
          id: 75,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "31",
        },
      ],
    },
    {
      id: 30,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "6734605d6b6329071eab9c36",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 70,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "30",
        },
        {
          id: 71,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "30",
        },
        {
          id: 72,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "30",
        },
      ],
    },
    {
      id: 28,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "673456896b6329071eab9c34",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 66,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "28",
        },
        {
          id: 67,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "28",
        },
        {
          id: 68,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "28",
        },
      ],
    },
    {
      id: 27,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "6734565a6b6329071eab9c33",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 63,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "27",
        },
        {
          id: 64,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "27",
        },
        {
          id: 65,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "27",
        },
      ],
    },
    {
      id: 26,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "6734499d2e873df094225ea7",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 60,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "26",
        },
        {
          id: 61,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "26",
        },
        {
          id: 62,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "26",
        },
      ],
    },
    {
      id: 25,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "673446482e873df094225ea6",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 57,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "25",
        },
        {
          id: 58,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "25",
        },
        {
          id: 59,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "25",
        },
      ],
    },
    {
      id: 22,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672c89e0594d101b6c3ec594",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "308807000",
        name: '"MYTAXI APP" MAS`ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 52,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "22",
        },
        {
          id: 53,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "22",
        },
        {
          id: 54,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "22",
        },
      ],
    },
    {
      id: 21,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672c88d5594d101b6c3ec593",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 49,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "21",
        },
        {
          id: 50,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "21",
        },
        {
          id: 51,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "21",
        },
      ],
    },
    {
      id: 20,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672c728d594d101b6c3ec592",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 46,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "20",
        },
        {
          id: 47,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "20",
        },
        {
          id: 48,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "20",
        },
      ],
    },
    {
      id: 19,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672c6a0b594d101b6c3ec591",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 43,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "19",
        },
        {
          id: 44,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "19",
        },
        {
          id: 45,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "19",
        },
      ],
    },
    {
      id: 18,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672b932511514747e43264af",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 40,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "18",
        },
        {
          id: 41,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "18",
        },
        {
          id: 42,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "18",
        },
      ],
    },
    {
      id: 17,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672b8dd711514747e43264ae",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 37,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "17",
        },
        {
          id: 38,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "17",
        },
        {
          id: 39,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "17",
        },
      ],
    },
    {
      id: 16,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672b8da911514747e43264ad",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 34,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "16",
        },
        {
          id: 35,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "16",
        },
        {
          id: 36,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "16",
        },
      ],
    },
    {
      id: 15,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672b67a611514747e43264ac",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 31,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "15",
        },
        {
          id: 32,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "15",
        },
        {
          id: 33,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "15",
        },
      ],
    },
    {
      id: 14,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672b672511514747e43264ab",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 28,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "14",
        },
        {
          id: 29,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "14",
        },
        {
          id: 30,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "14",
        },
      ],
    },
    {
      id: 13,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672b3e7cf08a2680d2db64c4",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 25,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "13",
        },
        {
          id: 26,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "13",
        },
        {
          id: 27,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "13",
        },
      ],
    },
    {
      id: 12,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "672b3e37f08a2680d2db64c3",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 22,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "12",
        },
        {
          id: 23,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "12",
        },
        {
          id: 24,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "12",
        },
      ],
    },
    {
      id: 9,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "67249303b58025d85dd2d1c6",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 17,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "9",
        },
        {
          id: 18,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "9",
        },
        {
          id: 19,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "9",
        },
      ],
    },
    {
      id: 7,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 13,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "7",
        },
        {
          id: 14,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "7",
        },
        {
          id: 15,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "7",
        },
      ],
    },
    {
      id: 6,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 10,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 2,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "6",
        },
        {
          id: 11,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "6",
        },
        {
          id: 12,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "6",
        },
      ],
    },
    {
      id: 5,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 7,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 1,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "5",
        },
        {
          id: 8,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "5",
        },
        {
          id: 9,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "5",
        },
      ],
    },
    {
      id: 4,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "67222620da913387f5f8086c",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 4,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 1,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "4",
        },
        {
          id: 5,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "4",
        },
        {
          id: 6,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "4",
        },
      ],
    },
    {
      id: 3,
      order_id: 10123856,
      driver_id: 10133,
      client_id: 47754,
      corp_id: 0,
      waybill_local_id: "67221bad91e2dcf9cd7675e0",
      delivery_type: 2,
      waybill_doc: {
        waybill_no: "postman-waybill",
        waybill_date: "2024-10-28T00:00:00Z",
      },
      contract_doc: {
        contract_no: "postman-contract",
        contract_date: "2024-10-28T00:00:00Z",
      },
      sender: {
        tin_or_pinfl: "304280837",
        name: '"MY TAXI OPS" MAS\'ULIYATI CHEKLANGAN JAMIYAT',
      },
      receiver: {
        tin_or_pinfl: "310659219",
        name: '"AKFA ALUMINIUM" mas`uliyati cheklangan jamiyati',
      },
      products: [
        {
          id: 1,
          product_name: "choy1",
          catalog_code: "00302003002001001",
          catalog_name:
            "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
          package_code: "1393480",
          package_name: "dona (paket) ",
          amount: 1,
          price: 6969,
          delivery_sum: 6969,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "3",
        },
        {
          id: 2,
          product_name: "choy2",
          catalog_code: "00302003002001001",
          catalog_name:
            "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
          package_code: "1497469",
          package_name: "dona (paket) 200 gramm",
          amount: 1,
          price: 69690,
          delivery_sum: 69690,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "3",
        },
        {
          id: 3,
          product_name: "choy3",
          catalog_code: "00302003002001001",
          catalog_name:
            "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
          package_code: "1218835",
          package_name: "dona (pэt butilka) 1,5 litr",
          amount: 1,
          price: 69696,
          delivery_sum: 69696,
          weight_netto: 0,
          weight_brutto: 0,
          WaybillId: "3",
        },
      ],
    },
  ],
};
