import { FormProvider, useForm } from "react-hook-form";

import TTN from "./ttn";
import { yupResolver } from "@hookform/resolvers/yup";
import { WaybillSaveSchema } from "./schema";
import { WaybillSaveType } from "../../services/ttn/use-save-waybill";

const TTNView = () => {
  const form = useForm<WaybillSaveType>({
    resolver: yupResolver(WaybillSaveSchema),
    defaultValues: {
      created_at: new Date().toISOString(),
    },
    reValidateMode: "onChange",
  });

  return (
    <FormProvider {...form}>
      <TTN />
    </FormProvider>
  );
};

export default TTNView;
