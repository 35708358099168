import { useQuery } from "react-query";
import request from "../request";
import { useGetClientProfile } from "../queries/use-get-client-profile";

interface TTNInfo {
  id: number;
  name: string;
  inn: string;
  is_blocked: boolean;
  self_created: boolean;
}

const useGetTTNCompanyInfo = () => {
  const profile = useGetClientProfile();

  return useQuery(
    ["v1/corporates/", profile?.data?.comp_id, "ttn-info"],
    () =>
      request?.private
        ?.get<TTNInfo>(`v1/corporates/${profile?.data?.comp_id}/ttn-info`)
        .then((res) => res?.data),
    { enabled: !!profile?.data?.comp_id }
  );
};

export default useGetTTNCompanyInfo;
