import TTNHeader from "./components/ttn-header";

import { useTranslation } from "react-i18next";
import DataField from "./components/data-field";
import {
  TTNIconButton,
  TTNSectionTitle,
  TTNTextField,
} from "./components/styled";
import {
  Box,
  Button,
  ButtonBase,
  ButtonBaseProps,
  CircularProgress,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import useCreateWaybill, {
  WaybillSaveType,
} from "../../services/ttn/use-create-waybill";
import { TTNDeliveryTypes } from "./types";
import useGetUserInformation from "../../services/ttn/use-get-user-information";
import useToggle from "../../hooks/use-toggle";
import { useEffect, useState } from "react";
import TTNProductItem from "./components/ttn-product";
import { BoxIcon, Plus } from "lucide-react";
import { COLORS } from "../../configs/colors.style";
import PlusIcon from "../../components/icons/plus";
import useGetProductsFromHistory from "../../services/ttn/use-get-products-from-history";

interface ProductSearchProps extends ButtonBaseProps {
  product_name: string;
  catalog_name: string;
  isProductCreate?: boolean;
}

const ProductSearch: React.FC<ProductSearchProps> = ({
  children,
  product_name,
  catalog_name,
  isProductCreate,
  sx,
  ...props
}) => {
  return (
    <ButtonBase
      sx={{
        py: 1,
        px: 1.5,
        textAlign: "start",
        justifyContent: "flex-start",
        display: "flex",
        width: "100%",
        ...sx,
      }}
      {...props}
    >
      <BoxIcon
        color={COLORS.textGraySecondary}
        style={{
          width: "24px",
          height: "24px",
          minWidth: "24px",
        }}
      />
      <Box sx={{ ml: 1.25, width: "calc(100% - 48px)" }}>
        <Typography fontSize={15} fontWeight={500}>
          {product_name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            mt: 0.5,
            fontSize: 13,
            fontWeight: 400,
            color: COLORS.textGray,
          }}
          noWrap
        >
          {catalog_name}
        </Typography>
      </Box>
      {isProductCreate && (
        <Plus style={{ minWidth: "16px", height: "16px", color: "#007AFF" }} />
      )}
    </ButtonBase>
  );
};

const TTNForm = () => {
  const { t } = useTranslation();

  const isReceiverFocused = useToggle();
  const [productName, setProductName] = useState<string | null>("");

  const getUserInfo = useGetUserInformation();
  const historyProducts = useGetProductsFromHistory({
    product_name: productName || "",
  });
  const { mutate } = useCreateWaybill();

  const form = useFormContext<WaybillSaveType>();
  const { fields, append } = useFieldArray({
    control: form.control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "products", // unique name for your Field Array
  });

  const { delivery_type, created_at } = form.watch();

  const submit = (value: WaybillSaveType) => {
    mutate(
      {
        ...value,
        products: value?.products?.map((product) => ({
          ...product,
          delivery_sum: +product?.amount * +product?.price,
        })),
      },
      {
        onSuccess: () => {
          form.reset({ created_at: new Date().toISOString() });
        },
      }
    );
  };

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <TTNHeader
        title={t(`ttn.delivery-type.${delivery_type}`)}
        back={() =>
          form.reset(
            {
              created_at: new Date().toISOString(),
              delivery_type: undefined,
              waybill_doc: {
                waybill_no: undefined,
                waybill_date: undefined,
              },
              contract_doc: {
                contract_no: undefined,
                contract_date: undefined,
              },
              receiver: {
                name: undefined,
                tin_or_pinfl: undefined,
              },
              products: [],
            },
            undefined
          )
        }
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25, p: 2 }}>
        <DataField
          label={t("Creation time")}
          value={new Date(created_at)?.toLocaleString([], {
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        />
        <Controller
          name="sender"
          control={form.control}
          render={({ field }) => {
            return <DataField label={t("sender")} value={field?.value?.name} />;
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
          <TTNSectionTitle ml={0.5}>{t("Document")}</TTNSectionTitle>
          <Controller
            name="waybill_doc.waybill_no"
            control={form.control}
            render={({ field }) => {
              const errorMsg =
                form?.formState?.errors?.waybill_doc?.waybill_no?.message;

              return (
                <TTNTextField
                  label={t("Number of the waybill")}
                  variant="filled"
                  error={!!errorMsg}
                  helperText={errorMsg ? t(errorMsg) : ""}
                  {...field}
                />
              );
            }}
          />
          <Controller
            name="waybill_doc.waybill_date"
            control={form.control}
            render={({ field }) => {
              const errorMsg =
                form?.formState?.errors?.waybill_doc?.waybill_date?.message;

              return (
                <TTNTextField
                  label={t("Document date")}
                  variant="filled"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  error={!!errorMsg}
                  helperText={errorMsg ? t(errorMsg) : ""}
                  {...field}
                />
              );
            }}
          />

          {delivery_type !== TTNDeliveryTypes.WarehouseToWarehouse && (
            <>
              <Controller
                name="contract_doc.contract_no"
                control={form.control}
                render={({ field }) => {
                  const errorMsg =
                    form?.formState?.errors?.contract_doc?.contract_no?.message;

                  return (
                    <TTNTextField
                      label={t("Contract number")}
                      variant="filled"
                      error={!!errorMsg}
                      helperText={errorMsg ? t(errorMsg) : ""}
                      {...field}
                    />
                  );
                }}
              />
              <Controller
                name="contract_doc.contract_date"
                control={form.control}
                render={({ field }) => {
                  const errorMsg =
                    form?.formState?.errors?.contract_doc?.contract_date
                      ?.message;

                  return (
                    <TTNTextField
                      label={t("Contract date")}
                      variant="filled"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      error={!!errorMsg}
                      helperText={errorMsg ? t(errorMsg) : ""}
                      {...field}
                    />
                  );
                }}
              />
            </>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
          <TTNSectionTitle ml={0.5}>{t("recipient")}</TTNSectionTitle>
          <Controller
            name="receiver.tin_or_pinfl"
            render={({ field: { onBlur, onChange, ...field } }) => {
              const errorMsg =
                form?.formState?.errors?.receiver?.tin_or_pinfl?.message;

              return (
                <TTNTextField
                  label={t("TIN")}
                  variant="filled"
                  onChange={(event) => {
                    event.target.value = event.target.value.replace(/\D/g, "");

                    onChange(event);
                  }}
                  onBlur={(event) => {
                    const inn = event.target.value;

                    if (!!inn && inn.length > 8) {
                      getUserInfo.mutate(inn, {
                        onSuccess: (data) => {
                          console.log(data.name);
                          form.setValue("receiver.name", data.name);
                        },
                      });
                    }

                    onBlur();
                  }}
                  error={!!errorMsg}
                  helperText={errorMsg ? t(errorMsg) : ""}
                  {...field}
                />
              );
            }}
          />

          <Controller
            control={form.control}
            name="receiver.name"
            render={({ field: { onBlur, ...field } }) => {
              const errorMsg = form?.formState?.errors?.receiver?.name?.message;

              return (
                <TTNTextField
                  label={t("Title")}
                  variant="filled"
                  multiline
                  InputLabelProps={{
                    shrink: field.value?.length > 0 || isReceiverFocused.isOpen,
                  }}
                  onBlur={() => {
                    isReceiverFocused.close();
                    onBlur();
                  }}
                  onFocus={() => isReceiverFocused.open()}
                  error={!!errorMsg}
                  helperText={errorMsg ? t(errorMsg) : ""}
                  {...field}
                />
              );
            }}
          />
        </Box>

        {fields?.map((product, index) => (
          <TTNProductItem key={product.id} index={index} product={product} />
        ))}
        {productName === null && (
          <TTNIconButton
            color="secondary"
            onClick={() => {
              setProductName("");
            }}
          >
            <PlusIcon style={{ width: 16, height: 16 }} color="#007AFF" />
            <Typography
              sx={{
                fontSize: "15px !important",
                fontWeight: 500,
                ml: 1,
                color: "#007AFF",
              }}
            >
              {t("Add product")}
            </Typography>
          </TTNIconButton>
        )}

        {productName !== null && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
            <TTNSectionTitle ml={0.5}>{t("Product")}</TTNSectionTitle>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TTNTextField
                label={t("Name")}
                variant="filled"
                sx={{ flex: 1 }}
                value={productName}
                onChange={(e) => setProductName(e?.target?.value)}
              />
            </Box>
            <Box>
              {productName?.length >= 1 && (
                <ProductSearch
                  product_name={productName}
                  catalog_name={t("Not defined")}
                  isProductCreate
                  onClick={() => {
                    append({ product_name: productName } as any);
                    setProductName(null);
                  }}
                />
              )}
              {historyProducts?.data?.products?.map(
                ({ product_name, catalog_name, ...others }) => (
                  <ProductSearch
                    product_name={product_name}
                    catalog_name={catalog_name}
                    onClick={() => {
                      append({
                        product_name,
                        catalog_name,

                        ...others,
                      } as any);
                      setProductName(null);
                    }}
                  />
                )
              )}
              {historyProducts?.isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          p: 2,
          bgcolor: "background.paper",
        }}
      >
        <Button type="submit" fullWidth>
          {t("Add TTN")}
        </Button>
      </Box>
    </form>
  );
};

export default TTNForm;
