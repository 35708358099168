import request from "../request";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

export interface ProductType {
  product_name: string;
  catalog_code: string;
  catalog_name: string;
  package_code: string;
  package_name: string;
  amount: number;
  price: number;
  delivery_sum: number;
  weight_netto: number;
  weight_brutto: number;
}

interface WaybillDoc {
  waybill_no: string;
  waybill_date: string;
}

interface ContractDoc {
  contract_no: string;
  contract_date: string;
}

interface Entity {
  tin_or_pinfl: string;
  name: string;
}

export interface WaybillSaveType {
  delivery_type?: number;
  waybill_doc: WaybillDoc;
  contract_doc: ContractDoc;
  sender: Entity;
  receiver: Entity;
  products: ProductType[];
  created_at: string;
}

const useCreateWaybill = () => {
  const { i18n } = useTranslation();

  return useMutation({
    mutationFn: (data: WaybillSaveType) =>
      request.private.post(`/v1/waybill/create?lang=${i18n.language}`, data),
  });
};

export default useCreateWaybill;
