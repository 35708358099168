import { useQuery } from "react-query";
import request from "../request";
import useDebounce from "../../hooks/use-debounce";

const products = [
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 1,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "3",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "3",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "3",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 1,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "4",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "4",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "4",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 1,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "5",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "5",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "5",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "6",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "6",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "6",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "7",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "7",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "7",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "9",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "9",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "9",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "12",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "12",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "12",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "13",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "13",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "13",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "14",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "14",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "14",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "15",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "15",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "15",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "16",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "16",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "16",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "17",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "17",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "17",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "18",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "18",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "18",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "19",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "19",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "19",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "20",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "20",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "20",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "21",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "21",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "21",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "22",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "22",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "22",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "25",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "25",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "25",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "26",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "26",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "26",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "27",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "27",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "27",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "28",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "28",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "28",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "30",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "30",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "30",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "31",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "31",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "31",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "32",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "32",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "32",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "33",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "33",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "33",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "34",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "34",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "34",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "35",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "35",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "35",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "36",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "36",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "36",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "37",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "37",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "37",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "38",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "38",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "38",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "39",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "39",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "39",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "40",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "40",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "40",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "41",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "41",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "41",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "42",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "42",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "42",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "43",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "43",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "43",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "44",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "44",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "44",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "45",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "45",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "45",
  },
  {
    id: 0,
    product_name: "choy1",
    catalog_code: "00210007001001001",
    catalog_name:
      "Барча турдаги тортиб сотиладиган ва қадоқланган қора чой (қуруқ чой) Tudor Инглиз нонуштаси цейлон 25 пак қути",
    package_code: "1393480",
    package_name: "dona (paket) ",
    amount: 2,
    price: 6969,
    delivery_sum: 6969,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "46",
  },
  {
    id: 0,
    product_name: "choy2",
    catalog_code: "00210007001001001",
    catalog_name:
      "Ярим тайёр ун маҳсулотлари (барча турлари) Tasty шарқ чучваралари, Полиэтилен пакет 200 гр",
    package_code: "1497469",
    package_name: "dona (paket) 200 gramm",
    amount: 1,
    price: 69690,
    delivery_sum: 69690,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "46",
  },
  {
    id: 0,
    product_name: "choy3",
    catalog_code: "00210007001001001",
    catalog_name:
      "Алкоголсиз ичимликлар (газланган ва газланмаган) COCA-COLA ширин, ПЭТ бутилка 1,5 л.",
    package_code: "1218835",
    package_name: "dona (pэt butilka) 1,5 litr",
    amount: 1,
    price: 69696,
    delivery_sum: 69696,
    weight_netto: 0,
    weight_brutto: 0,
    WaybillId: "46",
  },
];

interface TTNHistoryProductType {
  id: number;
  product_name: string;
  catalog_code: string;
  catalog_name: string;
  package_code: string;
  package_name: string;
  amount: number;
  price: number;
  delivery_sum?: number;
  weight_netto: number;
  weight_brutto: number;
  WaybillId: string;
}

const useGetProductsFromHistory = ({
  product_name = "",
}: {
  product_name?: string;
}) => {
  const dProductName = useDebounce(product_name, 500);

  const params = new URLSearchParams({
    product_name: dProductName,
    corp_id: "1",
  }).toString();

  return useQuery({
    queryKey: ["/v1/waybill/search", params],
    queryFn: () =>
      request.private
        .get<{ products: TTNHistoryProductType }>(
          `/v1/waybill/search?${params}`
        )
        .then((res) => ({ products })),
    enabled: !!dProductName,
  });
};

export default useGetProductsFromHistory;
