import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { priceFromatter } from "../../../utils/price-formatter";

const DebtAlert: React.FC<{ amount: number | undefined }> = ({
  amount = 0,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ borderRadius: 4 / 3, bgcolor: "white", p: 2 }}>
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: 21,
          color: "#FC5A5A",
        }}
        variant="h3"
      >
        {priceFromatter(amount)} {t("UZS")}
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          mt: 1,
          fontWeight: 400,
          fontSize: 15,
          color: "#8C8C8C",
        }}
      >
        Trips are suspended. Top up your balance to continue using the service.
      </Typography>
      {/* <Button
        variant="contained"
        color="info"
        fullWidth
        sx={{
          mt: 2.5,
          color: "#007AFF",
          bgcolor: "#007AFF1A",
          ":hover": {
            bgcolor: "#007AFF35",
          },
        }}
      >
        Pay off the debt
      </Button> */}
    </Box>
  );
};

export default DebtAlert;
