import { useQuery } from "react-query";
import request from "../request";
import useDebounce from "../../hooks/use-debounce";
import { useGetClientProfile } from "../queries/use-get-client-profile";

interface TTNHistoryProductType {
  id: number;
  product_name: string;
  catalog_code: string;
  catalog_name: string;
  package_code: string;
  package_name: string;
  amount: number;
  price: number;
  delivery_sum?: number;
  weight_netto: number;
  weight_brutto: number;
  WaybillId: string;
}

const useGetProductsFromHistory = ({
  product_name = "",
}: {
  product_name?: string;
}) => {
  const profile = useGetClientProfile();
  const dProductName = useDebounce(product_name, 500);

  const params = new URLSearchParams({
    product_name: dProductName,
    corp_id: profile?.data?.comp_id?.toString() || "",
  }).toString();

  return useQuery({
    queryKey: ["/v1/waybill/search", params],
    queryFn: () =>
      request.private
        .get<{ products: Array<TTNHistoryProductType> }>(
          `/v1/waybill/search?${params}`
        )
        .then((res) => res?.data),
    enabled: !!dProductName && !!profile?.data?.comp_id,
  });
};

export default useGetProductsFromHistory;
