import * as Yup from "yup";

const ProductSchema = Yup.object().shape({
  product_name: Yup.string().required("required"),
  catalog_code: Yup.string().required("required"),
  catalog_name: Yup.string().required("required"),
  package_code: Yup.string().required("required"),
  package_name: Yup.string().required("required"),
  amount: Yup.number()
    .required("required")
    .positive("Количество должно быть положительным"),
  price: Yup.number()
    .required("required")
    .positive("Цена должна быть положительной"),
  delivery_sum: Yup.number()
    .required("required")
    .min(0, "Сумма доставки не может быть отрицательной"),
  weight_netto: Yup.number()
    .required("required")
    .positive("Вес должен быть положительным"),
  weight_brutto: Yup.number()
    .required("required")
    .positive("Вес должен быть положительным"),
});

const WaybillDocSchema = Yup.object().shape({
  waybill_no: Yup.string().required("required"),
  waybill_date: Yup.date().required("required").nullable(),
});

const ContractDocSchema = Yup.object({
  contract_no: Yup.string(),
  // .when("delivery_type", {
  //   is: (value: any) => value !== 1,
  //   then: Yup.string().required("required"),
  //   otherwise: Yup.string(),
  // }),
  contract_date: Yup.date(),
  // .when("delivery_type", {
  //   is: (value: any) => value !== 1,
  //   then: Yup.date().required("required"),
  //   otherwise: Yup.date(),
  // }),
});

const EntitySchema = Yup.object().shape({
  tin_or_pinfl: Yup.string().required("required"),
  name: Yup.string().required("required"),
});

export const WaybillSaveSchema = Yup.object().shape({
  delivery_type: Yup.number()
    .required("required")
    .integer("Тип доставки должен быть целым числом"),
  waybill_doc: WaybillDocSchema.required("required"),
  contract_doc: ContractDocSchema,
  // sender: EntitySchema.required("required"),
  receiver: EntitySchema.required("required"),
  products: Yup.array()
    .of(ProductSchema)
    .min(1, "Должен быть хотя бы один продукт"),
});
