import AdminHeader from "./components/header";
import {
  Box,
  ButtonBase,
  CircularProgress,
  InputBase,
  Typography,
} from "@mui/material";
import { LinkBtn } from "./components/styled";
import { ChevronLeft, ChevronRight, Plus, User } from "lucide-react";
import styled from "styled-components";
import SearchIcon from "../../components/icons/search";
import { useMemo, useState } from "react";
import MobileDrawer from "../../components/mobile/mobile-drawer/mobile-drawer";
import { formatUZB } from "../../utils/number-formatter";
import useDeleteCorpClient from "../../services/corp-panel/use-delete-corp-client";
import { useTranslation } from "react-i18next";
import EmployeeDetail from "./components/employee-detail";
import AddEmployee from "./components/add-employee";
import useModal from "../../hooks/use-modal";
import { useGetCorpInfiniteEmployees } from "../../services/corp-panel/use-get-corp-infinite-employees";
import InfiniteLoader from "../../components/infinite-loader/infinite-loader";

const EmployeeItem = styled(ButtonBase)`
  padding-left: 22px;
  padding-right: 22px;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  color: black;
`;

const Input = styled(InputBase)`
  height: 44px;
  padding: ${({ theme }) => theme.spacing(1.25)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  width: 100%;
  padding-left: 40px;
  font-weight: 400;
`;

const AdminEmployeesPage = () => {
  const [search, setSearch] = useState<string>("");
  const [client, setClient] = useState<number | null>(null);
  const addEmployeeModal = useModal();

  const { t } = useTranslation();

  const deleteCorpClient = useDeleteCorpClient();
  const infiniteEmployees = useGetCorpInfiniteEmployees(search);

  const employeesData =
    infiniteEmployees.data?.pages?.map((page) => page.clients).flat() || [];

  const clients = useMemo(() => {
    return Object?.fromEntries(
      employeesData?.map((client) => [client?.id, client]) || []
    );
  }, [employeesData]);

  const clientDetail = client ? clients?.[client] : null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <AdminHeader
        bgColor="#fff"
        leftButton={
          <LinkBtn to="/mobile/corp/admin">
            <ChevronLeft />
          </LinkBtn>
        }
        title={
          <Typography fontSize={17} fontWeight={600}>
            {t("employees")}{" "}
            <Typography fontWeight={400} component="span" color="#8C8C8C">
              13
            </Typography>
          </Typography>
        }
      />
      <Box
        px={2}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          py: 1.25,
        }}
      >
        <Box sx={{ position: "relative", flex: 1, width: "100%" }}>
          <SearchIcon
            style={{
              position: "absolute",
              left: "10px",
              top: "10px",
              bottom: "10px",
              zIndex: 100,
              width: "24px",
              height: "24px",
            }}
          />
          <Input
            placeholder={t("search")}
            value={search}
            onChange={(e) => setSearch(e.target?.value)}
          />
        </Box>
        <ButtonBase
          sx={{
            bgcolor: "#007AFF1A",
            width: "44px",
            height: "44px",
            borderRadius: 1,
            color: "#007AFF",
            display: "flex",
            alignItems: "center",
          }}
          onClick={addEmployeeModal.open}
        >
          <Plus />
        </ButtonBase>
      </Box>
      <InfiniteLoader
        hasMore={infiniteEmployees?.hasNextPage}
        loadMore={infiniteEmployees?.fetchNextPage}
        loading={
          (infiniteEmployees?.isFetchingNextPage ||
            infiniteEmployees?.isFetching) &&
          employeesData.length > 0
        }
      >
        {employeesData?.map((client, i) => (
          <EmployeeItem onClick={() => setClient(client?.id)}>
            <Box
              sx={{ display: "flex", alignItems: "center", color: "#C0C0C0" }}
            >
              <User />
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid #F3F3F3",
                flex: 1,
                py: 1,
                minHeight: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <Typography fontWeight={500} textAlign="start">
                  {client?.name}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={"#8C8C8C"}
                  mt={0.5}
                  textAlign="start"
                >
                  {formatUZB(client?.phone)}
                </Typography>
              </div>
              <Box ml="auto">
                <ChevronRight width={16} height={16} />
              </Box>
            </Box>
          </EmployeeItem>
        ))}
      </InfiniteLoader>
      {infiniteEmployees?.isLoading && employeesData.length === 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <MobileDrawer
        drawerTitle={clientDetail?.name}
        leftTitle
        open={!!client}
        onClose={() => setClient(null)}
      >
        <EmployeeDetail client_id={clientDetail?.id} />
      </MobileDrawer>
      <MobileDrawer
        drawerTitle={t("add_employee")}
        leftTitle
        open={!!addEmployeeModal?.isOpen}
        onClose={addEmployeeModal?.close}
      >
        <AddEmployee onClose={addEmployeeModal?.close} />
      </MobileDrawer>
    </Box>
  );
};

export default AdminEmployeesPage;
