import {
  Box,
  ButtonBase,
  FilledInput,
  FormHelperText,
  InputLabel,
  Typography,
} from "@mui/material";

interface CustomWindow extends Window {
  webkit?: any;
  AndroidHandler?: any;
  Android?: any;
}

declare let window: CustomWindow;

import { Users } from "lucide-react";
import React from "react";
import styled from "styled-components";
import DocumentIcon from "../../../components/icons/document";
import { Link } from "react-router-dom";
import { useGetClientProfile } from "../../../services/queries/use-get-client-profile";
import useGetCorpProfile from "../../../services/corp-panel/use-get-corp-profile";
import { priceFromatter } from "../../../utils/price-formatter";
import { useTranslation } from "react-i18next";
import MobileDrawer from "../../../components/mobile/mobile-drawer/mobile-drawer";
import useModal from "../../../hooks/use-modal";
import Button from "../../../components/buttons";
import Input from "../../../components/input";
import useGetCorpReports from "../../../services/corp-panel/use-get-corp-reports";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useAppSelector } from "../../../redux/hook";
import toast from "react-hot-toast";

const Wrapper = styled.div``;

const MainLinkButton = styled(Link)`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(0.75)};
  flex: 1;

  color: #000000;
  background-color: #0000000f;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

const MainButton = styled(ButtonBase)`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(0.75)};
  flex: 1;

  color: #000000;
  background-color: #0000000f;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

const formSchema = yup.object().shape({
  from: yup
    .date()
    .typeError("required field")
    .required()
    .max(new Date(), "From date cannot be in the future")
    .min(new Date("2022-09-01"), "Date must be after September 1, 2022"),
  to: yup
    .date()
    .typeError("required field")
    .required()
    .max(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "To date cannot be in the future"
    )
    .min(yup.ref("from"), "To date must be after from date"),
});

const AdminMainBlock = () => {
  const { t } = useTranslation();

  const { corp_id } = useAppSelector((state) => state.auth);

  const modal = useModal();

  const corpProfile = useGetCorpProfile();
  const getCorpReports = useGetCorpReports();

  const form = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      from: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .toISOString()
        .split("T")[0],
      to: new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .split("T")[0],
    },
  });

  const submit = (value: { from: string; to: string }) => {
    console.log({
      startDate: dayjs(value.from).format("YYYY-MM-DDTHH:mm:ss"),
      endDate: dayjs(value.to).format("YYYY-MM-DDTHH:mm:ss"),
      id: corp_id,
      companyName: corpProfile?.data?.name,
    });

    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.downloadReport
    ) {
      window.webkit.messageHandlers.downloadReport.postMessage({
        startDate: dayjs(value.from).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(value.to).format("YYYY-MM-DDTHH:mm:ss"),
        id: corp_id,
        companyName: corpProfile?.data?.name,
      });
      modal?.close();
    }

    // if (window?.AndroidHandler) {
    //   window?.AndroidHandler?.downloadReport?.({
    //     startDate: dayjs(value.from).format("YYYY-MM-DDTHH:mm:ss"),
    //     endDate: dayjs(value.to).format("YYYY-MM-DDTHH:mm:ss"),
    //     id: corp_id,
    //   });

    //   toast.success("Android is called is downloading");
    //   return modal?.close();
    // }

    try {
      window?.Android?.downloadReport?.(
        dayjs(value.from).format("YYYY-MM-DDTHH:mm:ss"),
        dayjs(value.to).format("YYYY-MM-DDTHH:mm:ss"),
        corp_id,
        corpProfile?.data?.name
      );
      modal?.close();
    } catch (error) {
      console.error("Failed to download report:", error);
      toast.error("Android is call is failed!");
    }

    // getCorpReports?.mutate(value, {
    //   onSuccess: (file) => {
    //     const url = window.URL.createObjectURL(file);
    //     const link = document.createElement("a");
    //     link.href = url;

    //     const fromDate = dayjs(value.from).format("DD-MM-YYYY");
    //     const toDate = dayjs(value.to).format("DD-MM-YYYY");

    //     const fileName = `${fromDate}_${toDate}.xlsx`;
    //     link.setAttribute("download", fileName);
    //     document.body.appendChild(link);
    //     link.click();
    //   },
    // });
  };

  return (
    <Wrapper>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography
          color="#8C8C8C"
          fontSize={15}
          fontWeight={400}
          textAlign="center"
        >
          {t("your_balance")}
        </Typography>
        <Typography fontSize={27} fontWeight={600} textAlign="center" mt={2}>
          {priceFromatter(corpProfile?.data?.balance || 0)} {t("UZS")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1.25, mt: 4.5 }}>
        <MainLinkButton to="/mobile/corp/employees">
          <Users />
          <Typography component="span" fontSize={13} fontWeight={500}>
            {t("employees")}
          </Typography>
        </MainLinkButton>
        <MainButton onClick={modal?.open}>
          <DocumentIcon />
          <Typography component="span" fontSize={13} fontWeight={500}>
            {t("reports")}
          </Typography>
        </MainButton>
      </Box>
      <MobileDrawer
        drawerTitle={t("reports")}
        open={modal?.isOpen}
        onClose={modal?.close}
        component="form"
        onSubmit={form.handleSubmit(submit)}
      >
        <InputLabel sx={{ mt: 2.5 }}>{t("From")}</InputLabel>
        <FilledInput
          placeholder="10/09/2024"
          type="date"
          {...form?.register("from")}
        />
        {form?.formState?.errors?.from?.message && (
          <FormHelperText>
            {t(form?.formState?.errors?.from?.message)}
          </FormHelperText>
        )}
        <InputLabel sx={{ mt: 2.5 }}>{t("To")}</InputLabel>
        <FilledInput
          placeholder="10/10/2024"
          type="date"
          {...form?.register("to")}
        />
        {form?.formState?.errors?.to?.message && (
          <FormHelperText>
            {t(form?.formState?.errors?.to?.message)}
          </FormHelperText>
        )}
        <Button color="primary" sx={{ mt: 2.5, mb: 2 }} type="submit">
          {t("download")}
        </Button>
      </MobileDrawer>
    </Wrapper>
  );
};

export default AdminMainBlock;
