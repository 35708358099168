import { useQuery } from "react-query";
import request from "../request";
import { useGetClientProfile } from "../queries/use-get-client-profile";
import { useAppSelector } from "../../redux/hook";

interface CorpOrderDetailType {
  id: number;
  time: string; // "2024-05-06T17:55:53Z"
  from_address?: string | null;
  to_address?: string | null;
  description: string | null;
  duration?: number;
  waiting_time?: number;
  tariff_name: string;
  cost: {
    minimal: number;
    distance: number;
    time: number;
    waiting: number;
    air_conditioner: number;
    door_to_door: number;
    promo: number;
    paid_from_bonus: number;
    surge: number;
    commission: number;
    total: number;
    ride: number;
  };
  car: {
    number?: string;
    model?: string;
    color?: string;
    image?: string;
  };
  driver: {
    name: string;
    photo?: string;
    rating: number;
    rides_count: number;
  };
  route: Array<[number, number]>;
}

const useGetCorpOrderDetail = (
  order_id: string | number | undefined | null
) => {
  const { corp_id, isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["corp-order", corp_id, order_id],
    queryFn: () =>
      request.private
        .get<CorpOrderDetailType>(
          `/v1/corporates/${corp_id}/orders/${order_id}`
        )
        .then((res) => res.data),
    enabled: isAuthenticated && !!corp_id && !!order_id,
  });
};
export default useGetCorpOrderDetail;
