import { Button, ButtonBase, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../../../configs/colors.style";

export const TTNSectionTitle = styled(Typography)`
  font-weight: 500;
  font-size: 15px;
  color: ${COLORS.textGray};
`;

export const TTNTextField = styled(TextField)(() => ({
  // input label when focused
  "& label": {
    color: COLORS.textGraySecondary,
    fontWeight: 500,
    fontSize: "15px",
    display: "flex",
  },
  "& label.MuiInputLabel-shrink": { color: COLORS.textGray, fontSize: "13px" },
  input: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "22px",
    paddingBottom: "8px",
    lineHeight: "15px",
  },
}));

export const TTNDeliveryTypeButton = styled(ButtonBase)`
  padding: ${(them) =>
    `${them.theme.spacing(2.25)} ${them.theme.spacing(1.5)}`};
  color: ${COLORS.textBlackMain};
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  background-color: ${COLORS.lightBg};
  border-radius: ${(them) => them.theme.borderRadius.small};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const TTNIconButton = styled(Button)`
  padding: 14px !important;
  min-width: 52px;
`;
