import { IconType } from "../../../types/utility.types";

const ArrowRightIcon: IconType = ({ color = "#999999", ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.666 8L3.33268 8M12.666 8L8.66602 12M12.666 8L8.66602 4"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRightIcon;
