import { Box, ButtonBase, CircularProgress } from "@mui/material";
import AdminHeader from "./components/header";
import AdminMainBlock from "./components/admin-main-block";
import LastTrips from "./last-trips";
import ChatIcon from "../../components/icons/chat";
import { ChevronLeft } from "lucide-react";
import CompanySwitchDrawer from "./components/company-switch-drawer";
import DebtAlert from "./components/debt-alert";
import useGetCorpClientProfile from "../../services/corp-panel/use-get-corp-client-profile";
import { Link } from "react-router-dom";
import { useGetClientProfile } from "../../services/queries/use-get-client-profile";
import toast from "react-hot-toast";
import useGetTokenFromSearchParams from "../../hooks/use-get-token-from-search-params";

interface CustomWindow extends Window {
  webkit?: any;
  AndroidHandler?: any;
  Android?: {
    backToNative: (message: string) => void;
  };
}

declare let window: CustomWindow;

const AdminPage = () => {
  const profile = useGetClientProfile();
  const corpProfile = useGetCorpClientProfile();

  useGetTokenFromSearchParams();

  if (corpProfile.isLoading || profile?.isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress size={48} />
      </Box>
    );

  return (
    <Box
      sx={{
        bgcolor: "#F3F3F3",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundImage: !corpProfile.data?.debt
          ? "url(/corp/bg-primary.svg)"
          : "url(/corp/bg-error.svg)",
      }}
    >
      <AdminHeader
        title={<CompanySwitchDrawer />}
        leftButton={
          <ButtonBase
            sx={{ p: 1.25 }}
            onClick={() => {
              if (
                window?.webkit &&
                window?.webkit?.messageHandlers &&
                window?.webkit?.messageHandlers?.backToNative
              ) {
                window?.webkit?.messageHandlers?.backToNative?.postMessage?.(
                  "backToNative"
                );
              }

              // if (window?.AndroidHandler) {
              //   window?.AndroidHandler?.backToNative?.("backToNative");

              //   toast.success("Android is called is downloading");
              //   return;
              // }

              try {
                window?.Android?.backToNative?.("backToNative");
              } catch (error) {
                toast.error("Android call failed");
              }
            }}
          >
            <ChevronLeft />
          </ButtonBase>
        }
        rightButton={
          <Link
            to={"https://t.me/mytaxicorp"}
            style={{ padding: "10px", display: "block" }}
          >
            <ChatIcon />
          </Link>
        }
      />
      <Box
        px={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2.5,
          mt: 2,
          pb: 2.5,
        }}
      >
        <AdminMainBlock />
        {!!corpProfile.data?.debt && (
          <DebtAlert amount={corpProfile.data?.debt} />
        )}
      </Box>
      {/* <SwipeableEdgeDrawer /> */}
      <LastTrips />
    </Box>
  );
};

export default AdminPage;
