import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCookie } from "typescript-cookie";
import { loadState, saveState } from "../../utils/storage";

export const langs = ["ru", "en", "uz"];

interface AuthStateType {
  isAuthenticated: boolean;
  auth_modal: boolean;
  token: string | null;
  refresh_token: string | null;
  language: "ru" | "en" | "uz";
  corp_id: number | null;
}

// const initialState: AuthStateType = {
//   auth_modal: false,
//   isAuthenticated: !!getCookie("token") || false,
//   token: getCookie("token") || "",
//   refresh_token: loadState("auth")?.refresh_token || "",
//   language:
//     localStorage.getItem("language") ||
//     langs.includes(navigator?.language?.slice(0, 2))
//       ? (navigator?.language?.slice(0, 2) as "ru" | "en" | "uz")
//       : "ru",
// };

const initialState: AuthStateType = {
  auth_modal: false,
  isAuthenticated: !!getCookie("token") || false,
  token: getCookie("token") || "",
  refresh_token: loadState("auth")?.refresh_token || "",
  language: loadState("language"),
  corp_id: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthModalOpen: (state) => {
      state.auth_modal = true;
    },
    setAuthModalClose: (state) => {
      state.auth_modal = false;
    },
    setAuth: (
      state,
      action: PayloadAction<{
        isAuthenticated: boolean;
        token: string;
        refresh_token: string | null;
      }>
    ) => {
      let expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 10);

      if (window.location.origin.includes("localhost")) {
        document.cookie = `token=${action.payload.token}; domain=localhost; path=/; expires=${expiryDate}`;
      } else {
        document.cookie = `token=${action.payload.token}; domain=.mytaxi.uz; path=/; expires=${expiryDate}`;
      }

      saveState("auth", { refresh_token: action.payload.refresh_token });

      state.isAuthenticated = action.payload.isAuthenticated;
      state.refresh_token = action.payload.refresh_token;
      state.token = action.payload.token;
    },
    logout: (state) => {
      document.cookie = `token=; domain=.mytaxi.uz; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      document.cookie = `token=; domain=localhost; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      localStorage.removeItem("auth");

      state.isAuthenticated = false;
      state.refresh_token = null;
      state.token = null;
    },
    setLanguage: (state, action: PayloadAction<"ru" | "en" | "uz">) => {
      saveState("language", action.payload);
      state.language = action.payload;
    },
    resetAuthSlice: (state) => {
      state = initialState;
    },
    setCorpId: (state, action: PayloadAction<number>) => {
      state.corp_id = action.payload;
    },
  },
});

export const {
  setAuthModalOpen,
  setAuthModalClose,
  setAuth,
  logout,
  setLanguage,
  resetAuthSlice,
  setCorpId,
} = authSlice.actions;
export default authSlice.reducer;
