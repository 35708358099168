import { useQuery } from "react-query";
import request from "../request";
import { useTranslation } from "react-i18next";

// type should be added after running the function
// lang=ru&search=03401001001008036&tin=310659219 search and lang mightn't work

interface ProductType {
  pkey: string;
  tin?: string;
  pinfl?: string | null;
  mxikCode: string;
  mxikFullName: string;
  groupName: string;
  className: string;
  positionName: string;
  subPositionName: string;
  brandName: string;
  attributeName: string;
  internationalCode: string;
  unitCode: null;
  unitName: null;
  unitValue: null;
  commonUnitCode: null;
  commonUnitName: null;
  units: null;
  packageNames: [
    {
      code: number;
      mxikCode: string;
      nameUz: string;
      packageType: string;
      nameRu: string;
      nameLat: string;
    }
  ];
  label: number;
  usePackage: string;
  usePackage2: string;
  isActive: number;
  createdAt: string;
}

const useGetTTNProductCategories = ({
  search = "",
  tin = "202099756",
}: {
  search?: string;
  tin?: string;
}) => {
  const { i18n } = useTranslation();

  const params = new URLSearchParams({
    lang: i18n?.language,
    // search: search,
    tin: tin,
  }).toString();

  return useQuery({
    queryKey: ["v1/waybill/tasnif-products"],
    queryFn: () =>
      request.private
        .get<Array<ProductType>>(`v1/waybill/tasnif-products?${params}`)
        .then((res) => res.data),
  });
};

export default useGetTTNProductCategories;
