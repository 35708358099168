import { Box, ButtonBase } from "@mui/material";
import { ChevronLeft } from "lucide-react";
import React, { ReactNode } from "react";

interface TTNHeaderProps {
  title?: ReactNode;
  back?: () => void;
}

const TTNHeader: React.FC<TTNHeaderProps> = ({ title, back }) => {
  return (
    <Box
      sx={{
        py: 1.25,
        px: 2,
        borderBottom: "1px solid #F9F9F9",
        position: "sticky",
        top: 0,
        bgcolor: "white",
        zIndex: 10,
        minHeight: "56px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <ButtonBase onClick={back}>
        <ChevronLeft style={{ minWidth: "24px", height: "24px" }} />
      </ButtonBase>
      <h2>{title}</h2>
      <Box sx={{ width: "24px", height: "24px" }}></Box>
    </Box>
  );
};

export default TTNHeader;
