import * as Yup from "yup";

const ProductSchema = Yup.object().shape({
  product_name: Yup.string().required("required field"),
  catalog_code: Yup.string().required("required field"),
  catalog_name: Yup.string().required("required field"),
  package_code: Yup.string().required("required field"),
  package_name: Yup.string().required("required field"),
  amount: Yup.number()
    .required("required field")
    .positive("The quantity must be positive"),
  price: Yup.number()
    .required("required field")
    .positive("The price must be positive")
    .typeError("required field"),
  weight_netto: Yup.number()
    .required("required field")
    .positive("The weight must be positive"),
  weight_brutto: Yup.number()
    .required("required field")
    .positive("The weight must be positive"),
});

const WaybillDocSchema = Yup.object().shape({
  waybill_no: Yup.string().required("required field"),
  waybill_date: Yup.date().required("required field").nullable(),
});

const ContractDocSchema = Yup.object()
  .shape({
    contract_no: Yup.string(),
    contract_date: Yup.date(),
  })
  .when("delivery_type", {
    is: (value: any) => value !== 1,
    then: (schema) => {
      schema.fields.contract_no = schema.fields.contract_no.required(
        "required field"
      );
      schema.fields.contract_date = schema.fields.contract_date.required(
        "required field"
      );

      return schema;
    },
    otherwise: (schema) => schema,
  });

const EntitySchema = Yup.object().shape({
  tin_or_pinfl: Yup.string().required("required field"),
  name: Yup.string().required("required field"),
});

export const WaybillSaveSchema = Yup.object().shape({
  delivery_type: Yup.number()
    .required("required field")
    .integer("Shipping type must be an integer"),
  waybill_doc: WaybillDocSchema.required("required field"),
  contract_doc: ContractDocSchema,
  sender: EntitySchema.required("required field"),
  receiver: EntitySchema.required("required field"),
  products: Yup.array()
    .of(ProductSchema)
    .min(1, "There must be at least one product"),
});
