import { ChevronLeft } from "lucide-react";
import AdminHeader from "./components/header";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { LinkBtn } from "./components/styled";
import useGetCorpOrders from "../../services/corp-panel/use-get-corp-orders";
import HistoryOrderCard from "./components/history-order-card";
import dayjs from "dayjs";
import i18n from "../../i18n";
import { Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useGetCorpInfiniteOrders } from "../../services/corp-panel/use-get-corp-infinite-orders";
import InfiniteLoader from "../../components/infinite-loader/infinite-loader";

const AdminHistoryPage = () => {
  const { t } = useTranslation();
  const orders = useGetCorpOrders();
  const infiniteOrders = useGetCorpInfiniteOrders();

  const ordersData = infiniteOrders.data?.pages
    ?.map((page) => page.orders)
    .flat()
    .filter((order) => !!order);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <AdminHeader
        bgColor="#fff"
        title={<Typography>{t("allTrips")}</Typography>}
        leftButton={
          <LinkBtn to="/mobile/corp/admin">
            <ChevronLeft />
          </LinkBtn>
        }
      />
      <InfiniteLoader
        hasMore={infiniteOrders?.hasNextPage}
        loadMore={infiniteOrders?.fetchNextPage}
        loading={
          infiniteOrders?.isFetchingNextPage || infiniteOrders?.isFetching
        }
      >
        <Stack p={2} flexDirection="column">
          {!infiniteOrders?.isLoading
            ? ordersData?.map((order, index) => (
                <Fragment key={order.id}>
                  {(index === 0 ||
                    dayjs(ordersData?.[index - 1]?.time).format(
                      "DD MMMM, dddd"
                    ) !== dayjs(order?.time).format("DD MMMM, dddd")) && (
                    <Typography variant="h3" sx={{ mt: index !== 0 ? 2.5 : 0 }}>
                      {dayjs(order?.time)
                        .locale(
                          i18n.language == "uz" ? "uz-latn" : i18n.language
                        )
                        .format("DD MMMM, dddd")}
                    </Typography>
                  )}
                  <HistoryOrderCard order={order} />
                </Fragment>
              ))
            : [1, 2, 3, 4].map((order) => (
                <Skeleton
                  variant="rectangular"
                  key={order}
                  sx={{
                    height: "131px",
                    width: "100%",
                    borderRadius: "12px",
                    mt: 1.25,
                  }}
                />
              ))}
        </Stack>
      </InfiniteLoader>
      {orders?.isSuccess &&
        (!orders?.data?.orders || orders?.data?.orders?.length === 0) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              flexDirection: "column",
              color: "#8C8C8C",
              padding: "20px",
            }}
          >
            <Typography fontSize={18} fontWeight={500}>
              {t("no trips yet")}
            </Typography>
          </Box>
        )}
    </Box>
  );
};

export default AdminHistoryPage;
