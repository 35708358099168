import SelectDeliveryType from "./select-delivery-type-view";
import TTNForm from "./ttn-form";

import { useFormContext } from "react-hook-form";

const TTN = () => {
  const form = useFormContext();

  const value = form.watch("delivery_type");

  return (
    <>{typeof value !== "number" ? <SelectDeliveryType /> : <TTNForm />}</>
  );
};

export default TTN;
