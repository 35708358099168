import { Box, CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";
import useElementOnScreen from "../../hooks/use-element-on-screen";

interface Props {
  hasMore: boolean | undefined;
  loadMore: () => void;
  loading?: boolean;
  rootMargin?: string;
  children: any;
}

const InfiniteLoader: React.FC<Props> = (props) => {
  const targetRef = useElementOnScreen({
    enabled: !!props.hasMore,
    onScreen: () => {
      props.loadMore();
    },
    rootMargin: props.rootMargin || "50%",
    threshold: 0.2,
    isLoading: !!props.loading,
  });

  return (
    <>
      {props.children}
      {props.loading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: "25px",
          }}
        >
          <CircularProgress size={22} />
        </Box>
      )}
      <span
        aria-label="bottom"
        ref={targetRef}
        style={{ visibility: "hidden" }}
      />
    </>
  );
};

InfiniteLoader.defaultProps = {
  loading: false,
};

export default InfiniteLoader;
