import { IconType } from "../../types/utility.types";

const PlusIcon: IconType = ({ color = "#434343", ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2499 1.75C11.2499 1.05964 10.6903 0.5 9.99992 0.5C9.30956 0.5 8.74992 1.05964 8.74992 1.75V9.50002H1.25C0.559644 9.50002 0 10.0597 0 10.75C0 11.4404 0.559644 12 1.25 12H8.74992V19.25C8.74992 19.9404 9.30956 20.5 9.99992 20.5C10.6903 20.5 11.2499 19.9404 11.2499 19.25V12H18.75C19.4404 12 20 11.4404 20 10.75C20 10.0597 19.4404 9.50002 18.75 9.50002H11.2499V1.75Z"
      fill={color}
    />
  </svg>
);

export default PlusIcon;
