import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

import { Box, ButtonBase } from "@mui/material";

const Wrapper = styled.div<{ bgColor: string; scrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  position: sticky;
  top: 0;
  background-color: ${({ bgColor }) => bgColor};
  z-index: 101;
  box-shadow: ${({ scrolled }) =>
    scrolled ? "0px -4px 36px 0px #0000001f" : "none"};
  &::before {
    content: "";
    position: fixed;
    top: -100px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background-color: white;
  }
`;

const Button = styled(ButtonBase)`
  padding: ${({ theme }) => theme.spacing(1.25)};
`;

interface AdminHeaderProps {
  bgColor?: string;

  title?: JSX.Element | JSX.Element[];
  leftButton?: JSX.Element | JSX.Element[];
  rightButton?: JSX.Element | JSX.Element[];
}

const AdminHeader: React.FC<AdminHeaderProps> = ({
  bgColor = "#f3f3f3",

  title,
  leftButton,
  rightButton,
}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Wrapper bgColor={bgColor} scrolled={scrolled}>
      <Box minWidth={44} minHeight={44}>
        {leftButton}
      </Box>
      {title}
      <Box minWidth={44} minHeight={44}>
        {rightButton}
      </Box>
    </Wrapper>
  );
};

export default AdminHeader;
