import styled from "styled-components";
import OtpInput from "react-otp-input";

export const OtpInputVerify = styled(OtpInput)`
  input {
    @media (max-width: 450px) {
      width: 40px !important;
      height: 40px !important;
      font-size: 20px !important;
    }
  }
`;
