import { createTheme } from "@mui/material/styles";
import { COLORS } from "./colors.style";

const theme = createTheme({
  palette: {
    primary: {
      light: COLORS.yellowStroke,
      dark: "#fbd308",
      main: "#fedd33",
    },
    error: {
      main: "#fc5a5a",
    },
    divider: "#efeded",
    info: {
      main: "#4B89FA",
    },
    background: {
      default: "#f8f8f8",
    },
    success: {
      main: "#3dd598",
      light: COLORS.greenStroke,
    },
    text: {
      primary: "#434343",
      secondary: "#999999",
    },
    common: {
      white: "#fff",
    },
    action: {
      focus: "#c8c8c8",
    },
    mode: "light",
    secondary: {
      main: COLORS.inputFill,
      dark: "#f5f5f5",
    },
    grey: {
      "300": "#F2F2F2",
      "700": "#8C8B88",
    },
  },
  shape: {
    borderRadius: 12,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    htmlFontSize: 10,
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "2.1rem",
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.9rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.7rem",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "1.9rem",
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: "1.7rem",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    body2: {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    caption: {
      fontSize: "1.3rem",
      fontWeight: 400,
      color: COLORS.textGray,
    },
    allVariants: {
      color: COLORS.textMain,
      lineHeight: 1.1,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 8px 40px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 46,
          height: 28,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            top: -1,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              left: "1.5px",

              "& + .MuiSwitch-track": {
                backgroundColor: "#FEDD33",
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              // color: 'grey',
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 25,
            height: 25,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#E9E9EA",
            opacity: 1,
            // transition: theme.transitions.create(['background-color'], {
            // 	duration: 500,
            // }),
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: COLORS.inputText,
          backgroundColor: COLORS.inputFill,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        sizeSmall: {
          input: {
            padding: "12px 8px",
          },
        },
        root: {
          borderRadius: "12px",
          backgroundColor: COLORS.inputFill,
          // ":hover": { backgroundColor: "#f5f5f5" },
          input: {
            padding: "12.72px 12px",
            fontSize: "15px",
            fontWeight: 500,
            lineHeight: "15px",
          },
          "& label": {
            display: "none !important",
          },
        },
      },
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiSelect-select.MuiSelect-filled": { borderRadius: "12px" },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          paddingTop: "14px !important",
          paddingBottom: "14px !important",
          fontSize: "15px",
          lineHeight: "15px",
          fontWeight: "600 !important",
          borderRadius: "12px",
        },
        sizeMedium: {
          paddingTop: "17.5px !important",
          paddingBottom: "17.5px !important",
          lineHeight: "17px",
          fontSize: "17px !important",
          fontWeight: "600 !important",
          borderRadius: "14px",
        },
        root: {
          lineHeight: 1,
          textTransform: "initial",
        },
        disabled: {
          bgcolor: "red",
        },
        startIcon: {
          width: "100%",
        },
      },
      defaultProps: {
        size: "medium",
        variant: "contained",
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "gray" },
          style: {
            border: `1px solid ${COLORS.divider}`,
          },
        },
        {
          props: { fullWidth: true },
          style: {
            ".MuiButton-startIcon": {
              width: "initial",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            backgroundColor: `${COLORS.inputFill}`,
            color: "#434343",
          },
        },
        {
          props: { variant: "stroke", color: "success" },
          style: {
            backgroundColor: COLORS.greenStroke,
            color: COLORS.additionalGreen,
            ":hover": {
              backgroundColor: COLORS.greenStroke,
            },
          },
        },
        {
          props: { variant: "stroke", color: "primary" },
          style: {
            backgroundColor: COLORS.yellowStroke + "!important",
            color: COLORS.buttonYellow + "!important",
            ":hover": {
              backgroundColor: COLORS.yellowStroke,
            },
          },
        },
        {
          props: { variant: "stroke", color: "error" },
          style: {
            backgroundColor: COLORS.errorStroke + "!important",
            color: COLORS.additionalRed + "!important",
            ":hover": {
              backgroundColor: COLORS.errorStroke,
            },
          },
        },
        {
          props: { variant: "stroke", color: "info" },
          style: {
            backgroundColor: COLORS.blueStroke,
            color: COLORS.additionalBlue,
            ":hover": {
              backgroundColor: COLORS.blueStroke,
            },
          },
        },
        {
          props: { disabled: true },
          style: {
            backgroundColor: "#F3F3F3 !important",
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.divider,
          height: "0.5px",
          border: "none",
          // transform: 'translateY(50%)',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "24px",
          boxShadow: "0px 8px 40px rgba(0, 0, 0, 0.1)",
        },
      },
    },
  },
  borderRadius: {
    small: "12px",
    medium: "18px",
    large: "20px",
    extraLarge: "24px",
  },
});

declare module "@mui/material/styles" {
  interface Theme {
    borderRadius: {
      small: string;
      medium: string;
      large: string;
      extraLarge: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    borderRadius?: {
      small?: string;
      medium?: string;
      large?: string;
      extraLarge?: string;
    };
  }
}

export default theme;
