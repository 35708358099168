import { Box, Typography } from "@mui/material";
import React from "react";

import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import dayjs from "dayjs";
import { CorpOrderType } from "../../../services/corp-panel/use-get-corp-orders";
import AddressMap from "../../../components/address-map/address-map";

interface HistoryOrderCardProps {
  order: CorpOrderType;
}

const HistoryOrderCard: React.FC<HistoryOrderCardProps> = ({ order }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: "1px solid #F0F0F0",
        overflow: "hidden",
        position: "relative",
        cursor: "pointer",
        mt: 1.25,
      }}
      component="a"
      href={`/mobile/corp/history/${order.id}`}
    >
      <AddressMap
        py={0}
        my={1}
        listItemProps={{ py: 0 }}
        datalist={[
          { address: order?.from_address },
          { address: order?.to_address },
        ]}
        divider={false}
      />
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          py: 1.5,
          px: 2.5,
          mb: -0.02,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <>
            <Typography lineHeight="15px">
              {dayjs(order?.time).format("hh:mm")}
            </Typography>
            <Box sx={{ mb: "5px", mx: 0.5 }}>
              <svg
                width="4"
                height="4"
                viewBox="0 0 4 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="1.75" cy="2" r="1.75" fill="#434343" />
              </svg>
            </Box>
            <Typography lineHeight="15px">
              {`${
                order?.total_cost &&
                order?.total_cost
                  .toString()
                  .split("")
                  .reverse()
                  .map((e, i) => (i % 3 == 2 ? " " + e : e))
                  .reverse()
                  .join("")
              } 
        ${t("sum")}`}
            </Typography>
          </>
        </Box>
        <Typography fontSize={13} fontWeight={400} color="#8C8C8C" mt={1.25}>
          {order?.client_name}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: "2px",
          bottom: "13px",
          height: "39px",
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          src="/comfort.png"
          alt="car"
        />
      </Box>
    </Box>
  );
};

export default HistoryOrderCard;
