import { useQuery } from "react-query";
import request from "../request";
import { waybills } from "../../views/TTN/mock-data";

export interface WaybillType {
  id: number;
  order_id: number;
  driver_id: number;
  client_id: number;
  corp_id: number;
  waybill_local_id?: string;
  delivery_type: number;
  waybill_doc: {
    waybill_no: string;
    waybill_date: string;
  };
  contract_doc: {
    contract_no: string | null;
    contract_date: string | null;
  };
  sender: {
    tin_or_pinfl: string;
    name: string;
  };
  receiver: {
    tin_or_pinfl: string;
    name: string;
  };
  products: Array<{
    id: number;
    product_name: string;
    catalog_code: string;
    catalog_name: string;
    package_code: string;
    package_name: string;
    amount: number;
    price: number;
    delivery_sum: number;
    weight_netto: number;
    weight_brutto: number;
    WaybillId: string;
  }>;
}

const useGetWaybills = () => {
  return useQuery({
    queryKey: ["/v1/waybill/client"],
    queryFn: () =>
      request.private
        .get<{ waybills: Array<WaybillType> }>(
          `/v1/waybill/waybills/?corp_id=1`
        )
        .then((res) => waybills?.waybills),
  });
};

export default useGetWaybills;
